import React, { useState, useEffect, useLayoutEffect } from "react";
import {
  Box,
  Button,
  Heading,
  ResponsiveContext,
  Text,
  Footer,
  Collapsible,
  Nav,
  Sidebar,
  Grid,
  Spinner,
  Card,
  CardFooter,
  Image,
} from "grommet";
import { Github, Menu, Up, Down } from "grommet-icons";
import { CSSTransition } from "react-transition-group";
import { ReactComponent as DlmtLogo } from "./img/dlmt_logo.svg";
import mapTileLogo from "./img/dlmt_logo.svg";
import apkLocatorGif from "./img/apk_locator_sample.gif";
import unusuallyComposedListGif from "./img/unusually_composed_list_sample.gif";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import * as L from "leaflet";
import "./index.css";
import Strings from "./strings/it.json";

const appSections = [
  { anchor: "about" },
  { anchor: "projects" },
  { anchor: "contacts" },
];

function App() {
  const size = React.useContext(ResponsiveContext);
  const isGridAvailable = Grid.available;
  const [showSidebar, setShowMenu] = useState(isLargeScreen(size));

  useLayoutEffect(() => {
    const showMenuOnStart = isLargeScreen(size);
    setShowMenu(showMenuOnStart);
  }, [isLargeScreen(size)]);

  useEffect(() => {
    //On reload
    const currSectionId = window.location.pathname.replace("/", "");
    smoothScrollToElement(currSectionId);
  }, []);

  return (
    <ResponsiveContext.Consumer>
      {(size) => (
        <Box id="mainContainer" background="light">
          {isGridAvailable ? (
            <Box>
              <TopContainer size={size} />
              <AppBody gridArea="main" size={size} />
              {/*!isLargeScreen(size) && (
                <MenuButton toggleSidebar={() => setShowMenu(!showSidebar)} />
              )
              <Grid
                columns={!isLargeScreen(size) ? ["full"] : ["80vw"]}
                rows={["flex"]}
                gap="small"
                areas={[
                  { name: "main", start: [0, 0], end: [0, 0] },
                  { name: "nav", start: [1, 0], end: [1, 0] },
                ]}
              >
                <AppBody gridArea="main" size={size} />
             
                <AppMenu
                  gridArea="nav"
                  size={size}
                  setShowMenu={setShowMenu}
                  showSidebar={showSidebar}
                  onMenuItemClick={() =>
                    !isLargeScreen(size) && setShowMenu(false)
                  }
                />
             
              </Grid>
               */}
              <AppFooter />
            </Box>
          ) : (
            <SiteNotSupported />
          )}
        </Box>
      )}
    </ResponsiveContext.Consumer>
  );
}

const isLargeScreen = (size) => size !== "xsmall" && size !== "small";

const changePageUrl = (url) => {
  window.history.replaceState(null, "Dylomite", url);
  smoothScrollToElement(url);
};

const smoothScrollToElement = (elementId) => {
  if (elementId && elementId !== "") {
    const element = document.getElementById(elementId);
    if (element) {
      element.scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
};

const openInNewTab = (url) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) {
    newWindow.opener = null;
  }
};

/**
 * TODO: Create text components to avoid using unsafe inner html
 * @param {*} string 

const boldTextParser = (string) => {
  const pattern = '(<b>)(.+)(<\/b>)';
  const regex = new RegExp(pattern, 'gm')
  let matches;
  while ((matches = regex.exec(string)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (matches.index === regex.lastIndex) { regex.lastIndex++; }

    matches.forEach((match, groupIndex) => {
      //TODO: console.log(`Found match, group ${groupIndex}: ${match}`);
    });
  }
}
 */

//----------COMPONENTS----------

const TopContainer = ({ props, size }) => (
  <Box
    id="topContainer"
    height={isLargeScreen(size) ? "90vh" : "100vw"} //Was 100vH
    background="light"
    align="center"
    justify="center"
  >
    <Box margin={{ horizontal: "large" }} align="center" justify="center">
      <DlmtLogo height="70vh" />
      <Text id="subtitle" size="xlarge" weight="lighter">
        {Strings.main_header_title}
      </Text>
    </Box>
    <Box
      margin={{ top: "large" }}
      pad="small"
      focusIndicator={false}
      onClick={() => {
        changePageUrl(appSections[0].anchor);
      }}
      align="center"
      justify="center"
    >
      <Down size="large" color="dark" />
    </Box>
  </Box>
);

const MenuButton = ({ props, toggleSidebar }) => (
  <Button
    icon={<Menu color="dark" size={"40"} />}
    style={{ position: "absolute", top: "2vh", right: "2vw", zIndex: "1" }}
    onClick={() => toggleSidebar()}
  />
);

function AppMenu({ props, size, showSidebar, setShowMenu, onMenuItemClick }) {
  return isLargeScreen(size) ? (
    /** LARGE SCREEN SIDEBAR */
    <Collapsible direction="horizontal" open={showSidebar}>
      <Sidebar pad="none">
        <Box
          style={mainPageStyle.appMenuLargeScreen}
          align="center"
          margin={{ top: "xlarge" }}
        >
          <SidebarContents
            textColor={"primaryDark"}
            textSize={"small"}
            onMenuItemClick={() => onMenuItemClick()}
          />
        </Box>
      </Sidebar>
    </Collapsible>
  ) : (
    /** SMALL SCREEN SIDEBAR */
    <Collapsible direction="vertical" open={showSidebar}>
      <CSSTransition
        in={showSidebar}
        timeout={300}
        transitionAppear={true}
        classNames={"alert"}
      >
        <Box
          fill
          className="back-drop-blur"
          style={mainPageStyle.appMenuSmallScreen}
        >
          <Box margin={{ top: "25vh" }}>
            <SidebarContents
              textColor={"dark"}
              textSize={"large"}
              onMenuItemClick={() => onMenuItemClick()}
            />
          </Box>
        </Box>
      </CSSTransition>
    </Collapsible>
  );
}

const SidebarContents = ({ textColor, textSize, onMenuItemClick }) => (
  <Nav gap="small">
    <Box>
      <Box flex>
        <MenuItem
          text={Strings.contacts_menu_title}
          textColor={textColor}
          textSize={textSize}
          onClick={() => {
            onMenuItemClick();
            changePageUrl(appSections[2].anchor);
          }}
        />
      </Box>
    </Box>
  </Nav>
);

const MenuItem = ({ props, anchor, text, textColor, textSize, onClick }) => (
  <Box
    align="center"
    justify="center"
    focusIndicator={false}
    onClick={() => onClick()}
    hoverIndicator
  >
    <Box>
      <Heading
        size={textSize}
        textAlign="center"
        color={textColor}
        margin={{ top: "small", bottom: "none" }}
        style={{ maxLines: "2" }}
        weight="bold"
      >
        {text}
      </Heading>
    </Box>
  </Box>
);

function AppBody({ props, size }) {
  return (
    <Box flex background="light" align="center" justify="center">
      <Box flex margin={"large"}>
        {/* ABOUT 
        <Heading
          id={appSections[0].anchor}
          margin={{ top: "none", bottom: "medium" }}
          size="4rem"
        >
          {Strings.about_header_title}
        </Heading>
        */}
        {/* CONTACTS */}
        <Heading
          id={appSections[2].anchor}
          title={Strings.contacts_header_title}
          margin={{ top: "large", bottom: "large" }}
          align="center"
          justify="center"
          textAlign="center"
          size="4rem"
        >
          {Strings.contacts_menu_title}
        </Heading>

        <Box>
          <MapGrid
            size={size}
            leftAreaName="contactsDesc"
            rightAreaName="contactsMap"
          >
            <Box gridArea="contactsDesc" align="center" justify="center">
              <Box margin={{ top: "medium" }}>
                <Text size="xlarge" weight="lighter">
                  {Strings.contacts_desc_1}
                </Text>
                <Text
                  size={isLargeScreen(size) ? "large" : "medium"}
                  weight="lighter"
                  margin={{ top: "small" }}
                >
                  {Strings.contacts_phone}
                </Text>
                <Text
                  size={isLargeScreen(size) ? "large" : "medium"}
                  weight="lighter"
                >
                  {Strings.contacts_mail}
                </Text>
                <Text
                  size={isLargeScreen(size) ? "large" : "medium"}
                  weight="lighter"
                >
                  {Strings.contacts_address}
                </Text>
              </Box>
            </Box>
            <OsmMapWrap gridArea="contactsMap" size={size} />
          </MapGrid>
        </Box>
      </Box>
    </Box>
  );
}

const ProjectCard = (props) => {
  const { projectTitle, projectDescription, projectPreviewImg, projectUrl } =
    props;
  return (
    <Card align="center" justify="center" pad="small" background="white">
      <Box width="100%" height="medium">
        <Image
          fit="cover"
          src={projectPreviewImg}
          alt={`${projectTitle} sample`}
        />
      </Box>
      <CardFooter flex pad="medium" direction="column">
        <Text weight="bold" color="black" size="large" textAlign="center">
          {projectTitle}
        </Text>
        <Text
          weight="lighter"
          color="black"
          textAlign="center"
          dangerouslySetInnerHTML={{ __html: projectDescription }}
        />
      </CardFooter>
      <Button
        primary
        color="primary"
        margin="small"
        size="large"
        label={
          <Text color="white" weight="bold">
            {Strings.projects_find_out_more_button}
          </Text>
        }
        icon={<Github color="white" />}
        onClick={() => {
          openInNewTab(projectUrl);
        }}
      />
    </Card>
  );
};

function OsmMapWrap({ size }) {
  const headquarterPosition = [45.68517327373444, 11.566380478436859];
  const zoomLevel = size === "xsmall" ? 7 : 8;
  const tileSize = size === "xsmall" ? 65 : 100;
  const tileIcon = L.icon({
    iconUrl: mapTileLogo,
    iconSize: [tileSize, tileSize], // size of the icon
    iconAnchor: [tileSize / 2, tileSize / 2], // point of the icon which will correspond to marker's location
    popupAnchor: [0, -tileSize / 2], // point from which the popup should open relative to the iconAnchor
  });
  let visibleAttribution =
    "&copy; <a href='https://www.openstreetmap.org/copyright'> OpenStreetMap</a> contributors" +
    "&nbsp;&nbsp;&nbsp;&nbsp;";

  const [mapContainer, setMapContainer] = useState(mapContainer);
  const [hasAlreadyComputedSafeMapSize, sethasAlreadyComputedSafeMapSize] =
    useState(false);
  const [mapWidth, setMapWidth] = useState(null);
  const [mapHeight, setMapHeight] = useState(null);
  useEffect(() => {
    if (mapContainer && !hasAlreadyComputedSafeMapSize) {
      sethasAlreadyComputedSafeMapSize(true);
      setTimeout(() => {
        const vh40 = (window.innerHeight * 40) / 100;
        const vh30 = (window.innerHeight * 30) / 100;
        //const vw80 = (window.innerWidth * 80) / 100;
        let containerWidth = mapContainer.clientWidth;
        //Small map fix: if (size == 'xsmall') { containerWidth *= 2 }
        setMapWidth(vh40); //containerWidth);
        setMapHeight(
          vh40 //isLargeScreen(size) ? vh30 : containerWidth //Math.min(containerWidth, isLargeScreen(size) ? vh40 : vh80)
        );
      }, 1000);
    }
  });

  return (
    <Box align="center" justify="center" ref={(e) => setMapContainer(e)}>
      {mapWidth && mapHeight ? (
        <OsmMap
          size={size}
          mapWidth={mapWidth}
          mapHeight={mapHeight}
          position={headquarterPosition}
          zoomLevel={zoomLevel}
          tileSize={tileSize}
          tileIcon={tileIcon}
          visibleAttribution={visibleAttribution}
        />
      ) : (
        <Spinner />
      )}
    </Box>
  );
}

const OsmMap = ({
  size,
  mapWidth,
  mapHeight,
  position,
  zoomLevel,
  tileSize,
  tileIcon,
  visibleAttribution,
}) => (
  <div id="map">
    <MapContainer
      id="mapContainer"
      style={{
        height: mapHeight,
        width: mapWidth,
        opacity: "0.8",
        borderRadius: "30px",
      }}
      center={position}
      zoom={zoomLevel}
      attributionControl={true}
      scrollWheelZoom={false}
      whenCreated={(map) => {
        //setMapRef(map)
      }}
    >
      <TileLayer
        attribution={visibleAttribution}
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <Marker position={position} icon={tileIcon}>
        <Popup>Dylomite Here ✌️</Popup>
      </Marker>
    </MapContainer>
  </div>
);

const AppFooter = ({ props }) => (
  <Footer width="100vw" background="primary" pad="large">
    <Box direction="row">
      <Box margin={{ horizontal: "large" }}>
        <Text color="white" weight="bold" margin={{ bottom: "small" }}>
          {Strings.footer_rag_soc}
        </Text>
        <Text color="white">{Strings.footer_piva}</Text>
        <Text color="white">{Strings.footer_address}</Text>
      </Box>
    </Box>
    <Box
      direction="row"
      align="center"
      justify="center"
      pad={"small"}
      onClick={() => {
        smoothScrollToElement("topContainer");
      }}
    >
      <Text color="white" margin={{ end: "small" }}>
        Top
      </Text>
      <Up color="white" />
    </Box>
  </Footer>
);

const SiteNotSupported = (props) => (
  <Box flex height="100%" justify="center" align="center" background="brand">
    <Text pad="small" margin="small" textAlign="center">
      Whoopsie, it looks like this site is not supported by your browser :(
    </Text>
    <Text pad="small" margin="small" size="xsmall" textAlign="center">
      Please download a modern browser to continue
    </Text>
  </Box>
);

const MapGrid = ({ children, size, leftAreaName, rightAreaName, ...props }) => (
  <SideToSideGrid
    size={size}
    columns={size === isLargeScreen(size) ? ["full", "flex"] : ["auto", "auto"]}
    leftAreaName={leftAreaName}
    rightAreaName={rightAreaName}
  >
    {children}
  </SideToSideGrid>
);

/**
 * Returns a grid that displays 2 column side to side if there's enough room
 * Otherwhise displays children one below the other
 */
const SideToSideGrid = ({
  children,
  size,
  rows,
  columns,
  leftAreaName,
  rightAreaName,
  ...props
}) => {
  return (
    <ResponsiveGrid
      rows={rows ? rows : ["auto", "flex"]}
      //columns={columns ? columns : ["1/2", "flex"]} //['1/2', 'flex'] ['50vw', 'flex']
      columns={columns}
      gap="medium"
      areas={{
        /*
       
        */
        xsmall: [
          { name: leftAreaName, start: [0, 0], end: [1, 0] },
          { name: rightAreaName, start: [0, 1], end: [1, 1] },
        ],
        small: [
          { name: leftAreaName, start: [0, 0], end: [1, 0] },
          { name: rightAreaName, start: [0, 1], end: [1, 1] },
        ],
        medium: [
          { name: leftAreaName, start: [0, 0], end: [1, 0] },
          { name: rightAreaName, start: [1, 0], end: [1, 0] },
        ],
        middle: [
          { name: leftAreaName, start: [0, 0], end: [1, 0] },
          { name: rightAreaName, start: [1, 0], end: [1, 0] },
        ],
        large: [
          { name: leftAreaName, start: [0, 0], end: [1, 0] },
          { name: rightAreaName, start: [1, 0], end: [1, 0] },
        ],
      }}
    >
      {children}
    </ResponsiveGrid>
  );
};

const ResponsiveGrid = ({ children, areas, ...props }) => {
  const size = React.useContext(ResponsiveContext);
  return (
    <Grid responsive={true} areas={areas[size]} {...props}>
      {children}
    </Grid>
  );
};

const mainPageStyle = {
  appMenuLargeScreen: {
    position: "sticky",
    top: "0",
  },
  appMenuSmallScreen: {
    position: "absolute",
    top: "0",
    right: "0",
    //backdropFilter: `blur(30px)`,
    //background: 'rgba(255, 255, 255, 0.5)'
  },
};

export default App;
